import Link from "next/link";
import { FC } from "react";

const Logo: FC = () => {
  return (
    <>
      <Link href="/">
        <img src="/static/logo.png" />
      </Link>
      {/* language=CSS */}
      <style jsx>
        {`
          img {
            height: 80px;
          }
        `}
      </style>
    </>
  );
};

export default Logo;

import Link from "next/link";
import { FC, useContext, useState } from "react";

import FaIcon from "@components/FaIcon";
import { IconColor, IconType } from "@components/FaIcon/FaIcon";
import UserContext from "@components/UserContext";

const SmallViewPortNavigation: FC = () => {
  const [sidebarPosition, setSidebarPosition] = useState("100%");
  const { customer } = useContext(UserContext);

  return (
    <>
      <ul className="SmallViewPortNavigation">
        <li>
          <Link href="/cart">
            <a>
              <FaIcon type={IconType.Cart} color={IconColor.Secondary} size="20px" />
            </a>
          </Link>
        </li>
        <li
          onClick={() => {
            setSidebarPosition("0%");
          }}>
          <FaIcon type={IconType.Hamburger} color={IconColor.Secondary} size="24px" />
        </li>
      </ul>
      <ul
        className="slider-menu"
        onClick={() => {
          setSidebarPosition("100%");
        }}>
        <li>
          <FaIcon type={IconType.AngleLeft} color={IconColor.Secondary} size="24px" />
          &nbsp; Back
        </li>
        <li>
          <Link href="/course/catalog">
            <a>Course Catalog</a>
          </Link>
        </li>
        <li>
          <Link href="/support">
            <a>Support</a>
          </Link>
        </li>
        {customer?.guest ? (
          <li>
            <a href="/login">Sign in / Create</a>
          </li>
        ) : null}
        {!customer?.guest ? (
          <li>
            <Link href="/courses/purchased">
              <a>My Courses</a>
            </Link>
          </li>
        ) : null}
        {!customer?.guest ? (
          <li>
            <Link href="/gifts">
              <a>My Gifts</a>
            </Link>
          </li>
        ) : null}
        {!customer?.guest ? (
          <li>
            <Link href="/activate">
              <a>Activate License</a>
            </Link>
          </li>
        ) : null}
        {!customer?.guest ? (
          <li>
            <Link href="/order/history">
              <a>Order History</a>
            </Link>
          </li>
        ) : null}
        {!customer?.guest ? (
          <li>
            <Link href="/logout">
              <a>Logout</a>
            </Link>
          </li>
        ) : null}
      </ul>
      {/* language=CSS */}
      <style jsx>
        {`
          .SmallViewPortNavigation {
            margin: 0;
            list-style-type: none;
            display: flex;
          }

          .SmallViewPortNavigation li {
            margin-left: 20px;
          }

          a {
            text-decoration: none;
          }

          .slider-menu {
            list-style-type: none;
            margin: 0;
            padding: 0;
            position: fixed;
            background-color: white;
            top: 0;
            left: calc(${sidebarPosition});
            z-index: 10000;
            height: 100%;
            width: 100%;
            transition: left 300ms ease;
          }

          .slider-menu li {
            display: flex;
            align-items: center;
            padding: 20px;
            border-bottom: 1px solid rgb(168, 182, 190);
          }

          @media screen and (min-width: 768px) {
            .SmallViewPortNavigation {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
};

export default SmallViewPortNavigation;

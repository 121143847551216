import Link from "next/link";
import { FC } from "react";

import Container from "@components/Container";
import GlobeIcon from "@components/GlobeIcon";
import MailIcon from "@components/MailIcon";

const Footer: FC = () => {
  return (
    <>
      <div className="Footer">
        <div className="links">
          <Container>
            <div className="link-items">
              <div style={{ flex: 1 }}>
                <GlobeIcon />
                {` `}
                <Link href="http://www.mofmc.com/">
                  <a>www.mofmc.com/</a>
                </Link>
              </div>
              <div style={{ flex: 0 }}>
                <ul className="link-list">
                  <li>
                    <MailIcon />
                    {` `}
                    <a href="mailto:support@mofmc.com">support@mofmc.com</a>
                  </li>
                  <li>
                    <Link href="/terms">
                      <a>Terms and Conditions</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/privacy">
                      <a>Privacy Policy</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <div className="sub-footer">
            <div>© 2022 Mt. Olive Family Medicine Center, Inc.</div>
          </div>
        </Container>
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .Footer {
          }

          .Footer a {
            color: #898989;
            text-decoration: none;
          }

          .links {
            background-color: black;
            font-size: 0.9rem;
          }

          .link-list {
            padding: 0;
            margin: 0;
            list-style-type: none;
            align-items: center;
          }

          .link-list li {
            margin: 0;
            white-space: nowrap;
            height: 30px;
          }

          .sub-footer {
            margin: 20px 0;
            display: flex;
            line-height: 1.5;
            font-size: 11px;
          }

          @media screen and (min-width: 1024px) {
            .link-items {
              display: flex;
            }

            .link-list {
              display: flex;
            }

            .link-list li {
              height: auto;
              margin: 0 0 0 20px;
            }
          }
        `}
      </style>
    </>
  );
};

export default Footer;

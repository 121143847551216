import { FC } from "react";

export enum IconType {
  Globe = "\\f0ac",
  Email = "\\f0e0",
  Cart = "\\f07a",
  AngleDown = "\\f107",
  AngleUp = "\\f106",
  AngleLeft = "\\f104",
  CheckCircle = "\\f058",
  Circle = "\\f111",
  Search = "\\f002",
  Hamburger = "\\f0c9",
  Copy = "\\f0c5",
}

export enum IconColor {
  Primary = "#94c1d0",
  Secondary = "#A8B6BE",
}

const FaIcon: FC<{ type: IconType; color?: IconColor; size?: string }> = ({
  type,
  color = IconColor.Primary,
  size,
}) => {
  return (
    <>
      <span className="FaIcon" />
      {/* language=CSS */}
      <style jsx>
        {`
          .FaIcon {
            font-family: "Font Awesome 5 Free-Solid-900";
            color: ${color};
            padding-top: 2px;
            line-height: 30px;
            ${size ? `font-size: ${size}` : null}
          }

          .FaIcon::before {
            content: "${type}";
          }
        `}
      </style>
    </>
  );
};

export default FaIcon;
